/* Salables */
.salables-list {
  display: flex;
  flex-direction: column;
}

.salables-list .description {
  margin-bottom: 5px;
}

.salables-row {
  display: flex;
  flex-direction: row;
  border: solid 1px #999;
  width: fit-content;
  padding: 2px 2px 2px 2px;
  margin: 0px 5px 2px 0px;
}

.salables-row label {
  width: 15em;
}

.salables-row .ducats {
  width: 11em;
  text-align: left;
  margin-right: 1px;
}

.salables-row .plat {
  width: 7em;
}
