/* Inventory */
.primes_list {
  display: flex;
  flex-direction: column;
  min-width: 1000px;
}

.prime_row {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.prime_row:nth-child(odd) div {
  background-color: #ddd;
}

.prime_row label {
  white-space: nowrap;
}

.prime_row .parts {
  display: flex;
  flex-direction: row;
  width: fit-content;
}

.prime_row .prime {
  width: 15em;
  padding: 1px 5px 1px 1px;
  border: solid 1px #999;
}

.prime_row .prime label {
  display: block;
  width: 10em;
}

.prime_row .part {
  width: 5.5em;
  padding: 1px 5px 1px 1px;
  border: solid 1px #999;
}

.prime_row .part label {
  display: block;
  width: 8em;
}

.primes_list input {
  width: 2em;
}
