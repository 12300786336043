/* Tabs and panels */
.logo {
  color: #666;
}

.logo-spiffy {
  color: #000;
}

.react-tabs__tab-list {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
}

.react-tabs__tab {
  border-left: 1px solid #999;
  border-top: 1px solid #999;
  width: fit-content;
  padding: 2px;
  text-align: center;
  cursor: pointer;
  flex: 1;
}

.react-tabs__tab[data-tab="about"] {
  border-right: 1px solid #999;
  flex: 1 100%;
}

.react-tabs__tab:last-child {
  border-right: 1px solid #999;
}

.react-tabs__tab--selected {
  background: #8888ff;
}

.react-tabs__tab-panel {
}

.react-tabs__tab-panel--selected {
  padding: 0.5em 0.5em 0.5em 0.5em;
  border: solid 1px #999;
}

/* Theming buttons */
#theme-buttons {
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}

#theme-buttons input {
  border: solid 2px #fff;
  margin: 0px 2px 0px 2px;
}

#theme-buttons input.selected {
  border-color: #00f;
}

#theme-buttons input[data-theme="light"] {
  color: #000;
  background: #fff;
}

#theme-buttons input[data-theme="dark"] {
  color: #fff;
  background: #000;
}
